<template>
  <div class="module-two list-scroll" v-infinite-scroll="loadMore">
    <template v-if="list.length > 0">
      <div class="list-item" v-for="l in list" :key="l.id">
        <div class="list-item-left">
          <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
        </div>
        <div class="list-item-middle">
          <div class="item-name scroll-hidden">{{ l.name }}</div>
          <div class="item-mes scroll-hidden">
            {{ l.mes }}
          </div>
        </div>
        <div class="list-item-right" @click="toDetail(l)">
          <i class="el-icon-view"></i>查看
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-box">
        <img src="@/assets/images/empty.jpg" alt="" />
        <p>暂无数据</p>
      </div>
    </template>
  </div>
</template>

<script>
import { getModuleBaseList } from "@/api/base.js";
export default {
  name: "ModuleTwo",
  data() {
    return {
      loadingList: false,
      searchObj: {
        page: 1,
        pageSize: 20,
        moduleCode: "",
        typeId: 0,
      },
      list: [],
      total: 0,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log("val", val);
        const { query } = val;
        this.searchObj.moduleCode = query.module_code;
        this.searchObj.typeId = query.id;
      },
    },
    "searchObj.typeId"() {
      this.getModuleBaseList();
    },
  },
  created() {
    // console.log("created", this.searchObj);
    this.getModuleBaseList();
  },
  methods: {
    getModuleBaseList(loadMore = false) {
      if (!loadMore) {
        this.searchObj.page = 1;
        this.list = [];
      }
      this.loadingList = true;
      let params = {
        pageindex: this.searchObj.page,
        pagesize: this.searchObj.pageSize,
        module_code: this.searchObj.moduleCode,
        type_id: this.searchObj.typeId,
      };
      getModuleBaseList(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            const { data } = res;
            this.list = this.list.concat(data.data);
            this.total = data.dataCount;
          }
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    loadMore() {
      if (this.list.length < this.searchObj.page * this.searchObj.pageSize) {
        return;
      }
      this.searchObj.page += 1;
      this.getModuleBaseList(true);
    },
    toDetail(item) {
      this.$emit("clickItem", item.name);

      this.$router.push({
        name: "DetailOne",
        query: {
          module_code: this.searchObj.moduleCode,
          id: this.searchObj.typeId,
          cid: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .module-two {
    align-content: flex-start;
    .list-item {
      margin-top: 20px;
      min-height: 96px;

      &:last-child {
        margin-bottom: 20px;
      }
      .list-item-left {
        width: 120px;
      }
      .list-item-middle {
        width: calc(100% - 120px - 86px);
        .item-name {
          font-size: 16px;
          // height: 36px;
          // line-height: 36px;
        }
        .item-mes {
          // height: calc(100% - 36px);
        }
      }
      .list-item-right {
        width: 86px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .module-two {
    .list-item {
      margin-top: 10px;
      min-height: 82px;

      &:last-child {
        margin-bottom: 10px;
      }

      .list-item-left {
        width: 84px;
      }
      .list-item-middle {
        width: calc(100% - 84px - 60px);

        .item-name {
          font-size: 14px;
          // height: 30px;
          // line-height: 30px;
        }
        .item-mes {
          // height: calc(100% - 30px);
        }
      }
      .list-item-right {
        width: 60px;
      }
    }
  }
}
.module-two {
  overflow-y: auto;
  .list-item {
    width: 100%;
    display: flex;

    .list-item-left {
      // height: 100%;
      background: #fff;
      border: 1px solid #e4e4e4;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .list-item-middle {
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      padding:10px;

      .item-name {
        // padding: 0 10px;
        font-weight: 700;
        // overflow-x: scroll;
        // white-space: nowrap;
      }

      .item-mes {
        margin-top: 6px;
        width: 100%;
        // padding: 0 10px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #585858;
        // overflow-y: auto;
        // overflow-y: visible;
      }
    }

    .list-item-right {
      background: #007cf9;
      font-size: 14px;
      font-family: SimSun;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      i {
        margin-right: 4px;
      }
    }
  }
}
</style>